$(document).on('turbo:load', function() {

  // -------- While creating a new program: choose a city by first choosing a province --------

  // Province and city fields in the form
  let $program_province_selection = $(".program-province-selection");
  let $program_city_selection = $(".program-city-selection");
  let $program_city_picker = $(".program-city-picker");

  // Shows the city field if we're on the edit page; hides the city field if we're on the new page
  (window.location.pathname.indexOf("edit") > -1) ? $program_city_selection.show() : $program_city_selection.hide();

  // Detecting which province was selected
  $program_province_selection.change(function () {
    $prov_selected_id = $(".program-province-selection :selected").val();
    show_cities_field($prov_selected_id);
  });

  // Showing the cities field if a province is selected (loading all Canada-wide cities is too slow)
  function show_cities_field($province_id) {
    $program_city_selection.show();
    $program_city_picker.select2({theme: "bootstrap4", placeholder: locale_translation.programs.loading_cities});

    // note: this still works with the `route-translator` gem, so we don't need to I18n the url
    $.get({
      url: "/get_all_cities_in_province",
      data: {prov_id: parseInt($province_id)}
    }).done(function (response) {
      populate_cities_field(response);
    });
  }

  // Populating the cities field with all cities of the selected province
  function populate_cities_field($cities) {
    $program_city_picker.empty();

    $program_city_picker.append('<option value=""></option>')
    $.each($cities, function (i, city) {
      $program_city_picker.append('<option value="' + city.id + '">' + city.city + '</option>');
    });

    $program_city_picker.select2({theme: "bootstrap4", placeholder: locale_translation.programs.select_or_type_city});
  }
});
