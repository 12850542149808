let map;

window.initMap = () => {
  map = undefined;
  const canada = { lat: 62, lng: -95 };
  // The map, centered on Canada
  map = new google.maps.Map(document.getElementById("density-map"), {
    zoom: 4,
    center: canada,
    streetViewControl: false,
    mapTypeControl: false,
  });

  // Info window for displaying details of a Program.
  // We only create one info window for the map and set its contents and
  // position when a marker is clicked.
  const infoWindow = new google.maps.InfoWindow();

  // note: this still works with the `route-translator` gem, so we don't need to I18n the url
  fetch("/feeds/program_density")
    .then((res) => res.json())
    .then((data) => data.features)
    .then((allLocations) =>
      allLocations.filter((loc) => loc.properties.members_sum > 0)
    )
    .then((memberLocations) => {
      memberLocations.forEach((loc) => {
        // Default google maps marker showing Program location
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(
            loc.geometry.coordinates[1],
            loc.geometry.coordinates[0]
          ),
          title: loc.properties.location,
          label:
            loc.properties.programs.filter((prog) => prog.members > 0).length >
            1
              ? String(loc.properties.programs.length)
              : null,
          zIndex: 10000,
          map: map,
        });
        marker.addListener("click", () => {
          const contents = loc.properties.programs
            .filter((prog) => prog.members > 0)
            .map((prog) => {
              return (
                `<h4>${prog.name}</h4>` +
                `<div class='location-name'>` +
                `${prog.location}` +
                `</div>` +
                `<div class='member-count'>` +
                `${prog.members} ${prog.members === 1 ? locale_translation.map.member_singular : locale_translation.map.member_plural}` +
                `</div>` +
                `<div class='fac-count'>` +
                `${prog.facilitators} ${
                  prog.facilitators === 1 ? locale_translation.map.pep_facilitator_singular : locale_translation.map.pep_facilitator_plural
                }` +
                `</div>`
              );
            });

          infoWindow.setContent(contents.join("<hr/>"));
          infoWindow.open(map, marker);
        });
      });
      return memberLocations;
    })
    .catch((err) =>
      console.log(`${locale_translation.map.error_loading_program_data} ${err}`)
    );

  // Draw a legend
  const legend = document.getElementById("legend");
  map.controls[google.maps.ControlPosition.TOP_RIGHT].push(legend);
};
