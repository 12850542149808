import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";
import { destroy } from "@rails/request.js";

export default class extends Controller {
    initialize() {
        this.submitInputDebounced = debounce(this.submitInput.bind(this), 2000);
    }

    connect() {
        var loc = $("#i18N").val() === "fr" ? "fr-CA" : "en";
        $.datepicker.setDefaults($.datepicker.regional[ loc ]);

        // Submits the target_date when changed (target_date is a jQuery datepicker widget) as it doesn't auto-submit
        // with the data-action "input->action-plan#submitInputDebounced:not(.exclude-from-submit)"
        $("#ui-datepicker-div").on("mouseup", (event) => {
            this.submitInputDebounced(event);
        });
    }

    // Submits the form along with the parent component's ID of the input field whose change triggered the submit
    submitInput(event) {
        // Removes previous hidden input with the name "data-turbo-stream-id"
        document.querySelector('input[name="data-turbo-stream-id"]')?.remove();

        // Adding the turbo-stream ID as a hidden input so it gets passed into the params
        // This ID will be used to trigger the "Saved" flash message in that component
        const componentTurboStreamId = this.getComponentTurboStreamIdOnInput(event.target);
        const hiddenInput = document.createElement("input");
        hiddenInput.type = "hidden";
        hiddenInput.name = "data-turbo-stream-id";
        hiddenInput.value = componentTurboStreamId;
        this.element.appendChild(hiddenInput);
        this.element.requestSubmit();
    }

    // Gets the parent component's turbo-stream ID to pass it in to the Rails update() method on form submit
    getComponentTurboStreamIdOnInput(element) {
        let parent = element.parentElement;
        while (parent) {
            if (parent.getAttribute("id")?.startsWith("component-")) {
                return parent.getAttribute("id");
            }
            parent = parent.parentElement;
        }
        return null;
    }

    // Initializes and shows calendar widget for target_date fields that were added dynamically via turbo
    initializeDatepicker(event) {
        if (event.target.classList.contains("hasDatepicker") === false) {
            var loc = ""; // "fr-CA";
            $(".action-plan-datepicker").datepicker({ dateFormat: 'M d, yy' }).datepicker("show");
        }
        // Triggers the "Saved" flash message for the datepicker
        this.submitInputDebounced(event);
    }

    // Adds styling to show the user which element will be deleted; proceeds with triggering deletion if user confirms
    confirmDelete(event) {
        const confirmationMsg = event.currentTarget.dataset.confirmationMsg;
        const pepActionPlanId = event.currentTarget.dataset.pepActionPlanId;
        const stepId = event.currentTarget.dataset.stepId;
        const turboStream = this.getTurboStreamToAddDeleteStyling(event);
        turboStream.classList.add("deletion-highlight");

        // On a setTimeout so the turbo-stream gets it's styling first
        setTimeout(() => {
            const confirmed = confirm(confirmationMsg);
            if (confirmed) this.makeDeleteRequest(pepActionPlanId, stepId);
            turboStream.classList.remove("deletion-highlight");
        }, 100);
    }

    // Grabs the turbo-stream element of the item in question to be deleted, so that styling can be applied to it.
    // The turbo-stream could be the individual 'step', it's parent 'action', or it's grandparent 'component'.
    getTurboStreamToAddDeleteStyling(clickEvent) {
        const stepSelector = "[id*='step-']";
        const actionSelector = "[id*='action-']";
        const componentSelector = "[id*='component-']";

        const stepTurboStream = clickEvent.target.closest(stepSelector);
        const actionTurboStream = clickEvent.target.closest(actionSelector);
        const componentTurboStream = clickEvent.target.closest(componentSelector);

        const stepsUnderParentAction = actionTurboStream.querySelectorAll(stepSelector).length - 1;
        const actionsUnderParentComponent = componentTurboStream.querySelectorAll(actionSelector).length - 1;

        if (stepsUnderParentAction === 1 && actionsUnderParentComponent === 1) {
            return componentTurboStream;
        } else if (stepsUnderParentAction === 1 && actionsUnderParentComponent > 1) {
            return actionTurboStream;
        } else {
            return stepTurboStream;
        }
    }

    async makeDeleteRequest(pepActionPlanId, stepId) {
        // note: this still works with the `route-translator` gem, so we don't need to I18n the url
        const url = `/member/pep_action_plans/${pepActionPlanId}/delete_step/${stepId}`;
        try {
            await destroy(url, { responseKind: "turbo-stream" });
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    }
}
