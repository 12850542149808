// Aggregate Report Charts

import Plotly from "plotly.js-dist";
import $ from "jquery";

// Initializes the select all option in .multiselect-with-select-all
const initMultiselectWithSelectAll = function () {
  $(".multiselect-with-select-all").each(function (index, select) {
    const $select = $(select);
    const selectAllOption = $select.find("option[value='']");
    const allOptionValues = $select.find("option").map(function () {
      return this.value;
    });

    selectAllOption.click(function (option) {
      $select.val(allOptionValues);
    });
  });
};

window.initAggregateReport = (chartData) => {
  // If there are results try and render the charts
  if (!document.getElementById("agg-report-page")) return;
  if (!chartData) return;

  initMultiselectWithSelectAll();

  if (chartData.num_programs < 1) return;

  // Box and Whisker Charts
  for (const period in chartData.box_chart_data) {
    for (const category in chartData.box_chart_data[period]) {
      Plotly.newPlot(
        `${category}_${period}_chart`,
        [
          {
            x: chartData.box_chart_data[period][category],
            type: "box",
            textposition: "bottom",
            name: "",
          },
        ],
        {
          showlegend: false,
          autosize: true,
          margin: {
            l: 5,
            r: 10,
            b: 35,
            t: 35,
            pad: 4,
          },
        },
        {
          displayModeBar: false,
          editable: false,
          responsive: true,
          staticPlot: true,
        }
      );
    }
  }

  // Trend Charts
  for (const category in chartData.trend_data) {
    Plotly.newPlot(
      `${category}_trend`,
      [
        {
          x: chartData.trend_data[category].x,
          y: chartData.trend_data[category].y,
          type: "scatter",
        },
      ],
      {
        showlegend: false,
        autosize: true,
        margin: {
          l: 25,
          r: 10,
          b: 30,
          t: 30,
          pad: 4,
        },
      },
      {
        displayModeBar: false,
        editable: false,
        responsive: true,
        staticPlot: false,
      }
    );
  }

  $("#agg-generate-report-btn").on("click", function () {
    if ($("#agg-report-form")[0].checkValidity()) {
      disableAndSpin($(this), locale_translation.aggregate_report.generating_report);
      $("#agg-report-form").submit();
    } else {
      $("#agg-report-form")[0].reportValidity();
    }
  });
};

function disableAndSpin(btn, text = locale_translation.uploading) {
  $(btn).html(`${text} <i class='fas fa-spinner fa-pulse'>`).prop("disabled", true);
}
