import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  password_field = null;
  
  connect() {
    this.password_field = $(this.element).find(".password")[0];
  }
  
  password(e) {
    if (this.password_field.type === "password") {
      e.currentTarget.classList.remove('fa-eye-slash');
      e.currentTarget.classList.add('fa-eye');
      this.password_field.type = "text";
    } else {
      e.currentTarget.classList.remove('fa-eye');
      e.currentTarget.classList.add('fa-eye-slash');
      this.password_field.type = "password";
    }
  }
}