// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// @rails-ujs is still being used for all existing functionality. For forms that use Turbo make sure to add the data
// attribute `data: { turbo: true }` with the optional attribute `turbo_stream: true`.
require("@rails/ujs").start();
require("@hotwired/turbo-rails");
require("@rails/activestorage").start();
require("channels");

require("trix");
require("@rails/actiontext");

//Using a few different jquery-ui elements, including a datepicker.
import "jquery-ui";
import 'jquery-ui-dist/jquery-ui.theme.min.css';
import 'jquery-ui-dist/jquery-ui.min.css';

const lozad = require("lozad");

// Register jquery keywords from plugins section of config/webpack/environment.js
// Needed for Filterrific, and toast notifications in _flash.html.erb partial.
import $ from "jquery";


window.jQuery = $;
window.$ = $;

global.$ = $;
global.jQuery = $;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "../stylesheets/application";
import "./bootstrap_custom.js";
import "./sign_up.js";
import "./jquery.fancybox.min.js";
import "./select2.full.min.js";
import "./filterrific-jquery.js";
import "./aggregate-reports";
import "./density-map";
import "./programs.js"
import "./datepicker-fr-CA.js";

// FRENCH/LANGUAGE TRANSLATIONS:
// A note: Don't put hardcoded text in Javascript. Put your text in the :javascript section in `config/locales/*.yml`
// files instead. The view layouts load the locale's yaml file and saves it into a Javascript variable `yaml_data`.
// Usage example in Javascript: `locale_translation.pep_question.incomplete`

// STIMULUS:
// Load all the controllers within this directory and all subdirectories. Controller files must be named *_controller.js
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
const application = Application.start();
const context = require.context("controllers", true, /.js$/);
application.load(definitionsFromContext(context));

let translations = [];
translations[ "fr" ] = {
  bold: "Gras",
  bullets: "Liste",
  byte:  "Octet",
  bytes: "Octets",
  captionPlaceholder: "Ajouter légende…",
  code: "Code",
  heading1: "Titre",
  indent: "Agrandir retrait",
  italic: "Cursif",
  link: "Hyperlier",
  numbers: "Énumération",
  outdent: "Réduire retrait",
  quote: "Citation",
  redo: "Refaire",
  remove: "Supprimer",
  strike: "Biffé",
  undo: "Annuler",
  unlink: "Désactiver l’hyperlien",
  url: "URL",
  urlPlaceholder: "Entrer une adresse URL...",
  GB: "Go",
  KB: "Ko",
  MB: "Mo",
  PB: "Po",
  TB: "To"
};

$(document).on("select2:open", () => {
  document.querySelector(".select2-search__field").focus();
})

// Trix localization
$(document).on("trix-initialize", () => {
  const lang = $("#i18N").val();

  if (lang === "fr") {
    let translation = translations["fr"];
    $("[name|='href']").attr("placeholder", translation.urlPlaceholder);
    $("[title|='Bold']").attr("title", translation.bold);
    $("[title|='Italic']").attr("title", translation.italic);
    $("[title|='Strikethrough']").attr("title", translation.strikethrough);
    $("[title|='Link']").attr("title", translation.link);
    $("[title|='Heading']").attr("title", translation.heading1);
    $("[title|='Quote']").attr("title", translation.quote);
    $("[title|='Code']").attr("title", translation.code);
    $("[title|='Bullets']").attr("title", translation.bullets);
    $("[title|='Numbers']").attr("title", translation.numbers);
    $("[title|='Increase Level']").attr("title", translation.indent);
    $("[title|='Decrease Level']").attr("title", translation.outdent);
    $("[title|='Undo']").attr("title", translation.undo);
    $("[title|='Redo']").attr("title", translation.redo);
    $("[value|='Link']").val(translation.link);
    $("[value|='Unlink']").val(translation.unlink);
  }
});

$(document).on("turbo:load", function () {
  const lang = $("#i18N").val();

  // Clean up any old pickers as they seem to stay around when using the browser back button
  $(".select2-container").remove();
  
  // Check if the map element exists and call the function to draw the map if it does
  if (document.getElementById("density-map")) {
    initMap();
  }

  // Make this match the local format in en.yml (short date format for text inputs)
  // Note: need to target `action-plan-datepicker` or else it doesn't target the calendar the user clicked on
  $('[data-toggle="datepicker"], [data-toggle="action-plan-datepicker"]').datepicker({
    dateFormat: 'M d, yy'
  });

  $(".dropdown-toggle").dropdown();
  $('[data-toggle="tooltip"]')
    .tooltip()
    .on("shown.bs.tooltip", function (e) {
      // When scrolling while hovering over an element, Safari does not trigger
      // the `onMouseOut` event on that element until /inertial-scrolling/
      // stops. This means these tooltips keep displaying while scrolling, which
      // makes for a poor experience. Force the tooltip to be hidden when
      // scrolling starts!
      const forceHide = () => {
        $(e.target).tooltip("hide");
        window.removeEventListener("scroll", forceHide);
      };

      window.addEventListener("scroll", forceHide);
    });

  const observer = lozad(".js-lozad", {
    rootMargin: "10px 0px", // syntax similar to that of CSS Margin
    threshold: 0.1, // ratio of element convergence
  });
  observer.observe();

  // Resources New
  const onClickAttachmentLabelHandler = function(event) {
    event.preventDefault();
    $(this).prev().click();
  }
  const onChangeFileHandler = function() {
    $(this).siblings(".file-chosen-label").text(this.files[0].name);
  }

  $(".add_fields").on("click", function (event) {
    event.preventDefault();
    var regexp, time, new_field;
    time = new Date().getTime();
    regexp = new RegExp($(this).data("id"), "g");
    new_field = $($(this).data("fields").replace(regexp, time));
    $(this).before(new_field);
    new_field.find(".attachment-label").on("click", onClickAttachmentLabelHandler);
    new_field.find(".form-control-file").on("change", onChangeFileHandler);
  });

  $(".attachment-label").on("click", onClickAttachmentLabelHandler);
  $(".form-control-file").on("change", onChangeFileHandler);

  // Aggregate Report CSV
  $("#agg-generate-csv-btn").on("click", function (event) {
    event.preventDefault();
    let csv_url = window.location.origin + window.location.pathname + '.csv' + window.location.search
    top.location.href = csv_url
  });

  // Resources remove
  $(document).on("click", ".remove_fields", function (event) {
    event.preventDefault();
    hide_closest_fieldset($(this));
  });

  //Deny membership reason show/hide
  $(document).on("change", ".denied-radio", function(event) {
    event.preventDefault();
    var denyReason = document.getElementsByClassName("denied-reason")[0];
    var message = document.getElementsByClassName("de-account-change-message")[0];
    var other_message = document.getElementsByClassName("ac-account-change-message")[0];
    var startButton = document.getElementsByClassName("de-change-activate-btn")[0];
    var otherButton = document.getElementsByClassName("ac-change-activate-btn")[0];
    var cancelButton = document.getElementById("member_view_cancel");
    var returnButton = document.getElementById("member_view_return");
    startButton.disabled = true;
    startButton.classList.add("disabled");
    startButton.parentElement.classList.add("disabled");
    otherButton.disabled = false;
    otherButton.classList.remove("disabled");
    otherButton.parentElement.classList.remove("disabled");
    message.style.display = "block";
    other_message.style.display = "none";
    denyReason.style.display = "block";
    returnButton.style.display = "none";
    cancelButton.style.display = "block";
    var invisible = document.getElementsByClassName("edit-user-button")[0];
    invisible.classList.remove("invisible");
  });
  $(document).on("change", ".activate-radio", function(event) {
    event.preventDefault();
    var denyReason = document.getElementsByClassName("denied-reason")[0];
    var message = document.getElementsByClassName("ac-account-change-message")[0];
    var other_message = document.getElementsByClassName("de-account-change-message")[0];
    var startButton = document.getElementsByClassName("ac-change-activate-btn")[0];
    var otherButton = document.getElementsByClassName("de-change-activate-btn")[0];
    var cancelButton = document.getElementById("member_view_cancel");
    var returnButton = document.getElementById("member_view_return");
    startButton.disabled = true;
    startButton.classList.add("disabled");
    startButton.parentElement.classList.add("disabled");
    otherButton.disabled = false;
    otherButton.classList.remove("disabled");
    otherButton.parentElement.classList.remove("disabled");
    message.style.display = "block";
    other_message.style.display = "none";
    denyReason.style.display = "none";
    returnButton.style.display = "none";
    cancelButton.style.display = "block";
    var invisible = document.getElementsByClassName("edit-user-button")[0];
    invisible.classList.remove("invisible");
  });

  $(document).on("change", "#user_account_status_event", function(event) {
    var message = document.getElementsByClassName("account-change-message")[0];
    var startButton = document.getElementsByClassName("change-activate-btn")[0];
    var cancelButton = document.getElementById("member_view_cancel");
    var returnButton = document.getElementById("member_view_return");
    startButton.style.display = "none";
    message.style.display = "block";
    returnButton.style.display = "none";
    cancelButton.style.display = "block";
    var invisible = document.getElementsByClassName("edit-user-button")[0];
    invisible.classList.remove("invisible");
  });

  $(document).on("change", ".form-control", function(event) {
    event.preventDefault();
    var invisible = document.getElementsByClassName("edit-user-button")[0];
    if (invisible) {
      invisible.classList.remove("invisible");
    }
  });

  $(document).on("click", ".modal-activating-link", function(event) {
    event.preventDefault();
    let target_url = event.currentTarget.dataset.url;
    $.ajax({
        url: target_url,
        type: "GET",
        dataType: 'html',
        success: function(response) {
            $("#modalContainer").html(response);
            $("#ahs-modal").modal({focus: true});
        }
    });
  })

  //PEP participants remove
  $(document).on("click", "#pep_participants .remove_fields", function(event) {
    event.preventDefault();
    let closest_fieldset = hide_closest_fieldset($(this));
    //Remove the pep_participants required attributes for form fields
    closest_fieldset.find("input[type=text]").removeAttr("required")
    closest_fieldset.find("select").removeAttr("required")
  });

  //Resource and PEP Participant fieldset hide
  function hide_closest_fieldset(thisObj) {
    thisObj.prev("input[type=hidden]").val("1");
    let fieldset_selector = thisObj.closest("fieldset");
    fieldset_selector.hide();
    thisObj.closest(".border-danger").find(".text-danger").removeClass("text-danger");
    thisObj.closest(".border-danger").removeClass("border-danger");
    return fieldset_selector;
  }

  // Inspire Confirm Community Guidelines
  $("#confirm-community-guidelines").change(function () {
    if ($(this)[0].checked) {
      $("#inspire-submit-review").removeAttr("disabled");
    } else {
      $("#inspire-submit-review").prop("disabled", true);
    }
  });
  $("#inspire-submit-review").on("click", function () {
    $("<input>", {
      type: "hidden",
      id: "submit_review",
      name: "submit_review",
      value: "true",
    }).appendTo("form");

    disableAndSpin($(this));

    $("form").submit();
  });
  $("#inspire-update-resource").on("click", function () {
    $("<input>", {
      type: "hidden",
      id: "update_resource",
      name: "update_resource",
      value: "true",
    }).appendTo("form");

    disableAndSpin($(this));

    $("form").submit();
  });

  // Inspire Sorting
  $("#resources_sort").sortable({
    axis: "y",
    handle: ".handle",
    update: function () {
      // note: this still works with the `route-translator` gem, so we don't need to I18n the url
      return $.post($(this).data("update-url"), $(this).sortable("serialize"));
    },
  });

  // PEP Criteria Clicking
  $(".EditPep")
    .find(".PepCheckbox")
    .change(function () {
      const $this = $(this);
      const criterionId = $this.data("criteria-id");
      $this.closest(".PepQuestion").addClass("loading");
      if ($this[0].checked) {
        updatePepCheckboxClick(this, criterionId, true);
      } else {
        updatePepCheckboxClick(this, criterionId, false);
      }
    });

  function updatePepCheckboxClick(checkbox, criterion_id, checked) {
    // note: this still works with the `route-translator` gem, so we don't need to I18n the url
    $.post("/member/pep/" + pepId + "/process_criterion", { criterion_id: criterion_id, checked: checked }).done(function (data) {
      const $this = $("#pep-score-id-" + data.question_id);
      $(checkbox).closest(".PepQuestion").removeClass("loading");
      if (data.score === 0) {
        $this.text(locale_translation.pep_question.incomplete);
        $this.closest(".pep-score").removeClass("pep-state-complete").addClass("pep-state-incomplete");
      } else {
        $this.closest(".pep-score").removeClass("pep-state-incomplete").addClass("pep-state-complete");
        $this.text(`${locale_translation.pep_question.level} ${data.score}`);
      }
    });
  }

  // PEP Narrative Clicking
  $(".EditPep")
    .find(".PepNarrativeCheckbox")
    .change(function () {
      const narrativeId = $(this).data("narrative-id");
      if ($(this)[0].checked) {
        updateNarrativeCheckboxClick(narrativeId, true);
      } else {
        updateNarrativeCheckboxClick(narrativeId, false);
      }
    });

  function updateNarrativeCheckboxClick(narrative_id, checked) {
    // note: this still works with the `route-translator` gem, so we don't need to I18n the url
    $.post("/member/pep/" + pepId + "/process_narrative", { narrative_id: narrative_id, checked: checked }).done(function (data) {
      $("#PepNarrativeStatus").text(locale_translation.saved_flash_message);
    });
  }

  // PEP Goal Changing
  // Weird behaviour (infinite loop of saving) with trix-editor with the jQuery events change, keyup, etc. Blur and
  // focusout worked as desired.
  $(".EditPep")
    .find(".pep-edit-trix-editor")
    .on("blur", function () {
      // the trix-editor needs a hidden input sibling that actually holds the value. But the trix-editor has to be the
      // element that's targeted for blur, because input is hidden. The trix-editor[input] and input[id] match
      const trixInputAttr = $(this).attr("input");
      let inputField = $("#" + trixInputAttr);

      const goalId = $(inputField).data("goal-id");
      const categoryID = $(inputField).data("category-id");
      const questionID = $(inputField).data("question-id");
      const textValue = $(inputField).val();

      if (textValue.length) {
        $.post("/member/pep/" + pepId + "/process_category_goal",
            { goal_id: goalId, category_id: categoryID, question_id: questionID, text: textValue }
        ).done(function () {
          $("#PepCategoryGoalStatus_" + goalId + "_QuestionID_" + questionID).text(locale_translation.saved_flash_message);
        });
      }
    });

  function openAndPrint(url) {
    var printWindow = window.open(url, 'Print');
    printWindow.onload = function (e) {
      this.print();
      //Close window once print is finished
      this.onafterprint = function () {
        this.close();
      };
    }

    // var myWindow=window.open('','','width=200,height=100');
    // myWindow.document.write("<p>This is 'myWindow'</p>");

    // myWindow.document.close();
    // myWindow.focus();
    // myWindow.print();
    // myWindow.close();
  }

  // Print link
  $(".print-link").on("click", function (e) {
    e.preventDefault();

    const url = $(this).data("url");
    openAndPrint(url);
  });

  attachCheckboxEvents();

  $("#submit-tally").on("click", function (e) {
    $("#pep-compare-form").submit();
  });

  $(".flag-link").on("click", function (e) {
    const dataSet = e.currentTarget.dataset;

    if (dataSet.kind === "ForumPost") {
      $("#flag_comment_id").val(dataSet.id);
    }
  });

  $(".resolved-action-radio").on("click", function (e) {
    if (e.target.value == "remove_content" || e.target.value == "reject_content") {
      $(".user-feedback-form").show();
    } else {
      $(".user-feedback-form").hide();
    }
  });

  // Check to see if the radio is checked on load.
  if (document.getElementById("edit-content-review") && $(".resolved-action-radio:checked").length > 0) {
    const checked_value = $(".resolved-action-radio:checked")[0].value;

    if (checked_value == "remove_content" || checked_value == "reject_content") {
      $(".user-feedback-form").show();
    } else {
      $(".user-feedback-form").hide();
    }
  }

  $(".pep-program-select").select2({
    theme: "bootstrap4",
  });

  $(".admin-city-picker").select2({ theme: "bootstrap4" });

  const filterCitiesByProvince = (allCities, prov, citySelect, includeBlank) => {
    const isAdmin = citySelect.hasClass("admin-city-picker");
    const blankOptionHtml = "<option value=''></option>";
    const filteredCities = $(allCities)
      .filter(`optgroup[label="${prov}"]`)
      .html();
    if (filteredCities) {
      if (includeBlank) {
        $(citySelect).html(blankOptionHtml + filteredCities);
      } else {
        $(citySelect).html(filteredCities);
      }

      // Show the city picker only on the signup form
      isAdmin || $(citySelect).parent().show();
    } else {
      $(citySelect).empty();
      // Hide the city picker only on the signup form
      isAdmin || $(citySelect).parent().hide();
    }
  };

  const adminCities = $(".admin-city-picker").html();

  filterCitiesByProvince(
    adminCities,
    $(".admin-province-picker :selected").text(),
    $(".admin-city-picker"),
    false
  );

  $(".admin-province-picker").change(() => {
    filterCitiesByProvince(
      adminCities,
      $(".admin-province-picker :selected").text(),
      $(".admin-city-picker"),
      false
    );
  });

  $(".city-picker").select2(
    {
      theme: "bootstrap4",
      placeholder: locale_translation.programs.select_or_type_city
    }
  );

  const cities = $(".city-picker").html();

  $(".province-picker").change(() => {
    filterCitiesByProvince(
      cities,
      $(".province-picker :selected").text(),
      $(".city-picker"),
      true
    );
  });

  $(".program-picker").select2(
    {
      theme: "bootstrap4",
      placeholder: locale_translation.programs.select_or_type_program
    }
  );

  $(".language-picker").select2(
    {
      theme: "bootstrap4"
    }
  );

  $(".generic-select").select2(
    {
      theme: "bootstrap4",
      minimumResultsForSearch: Infinity,
    }
  );

  $(".dynamic-select").select2(
    {
      theme: "bootstrap4",
      minimumResultsForSearch: Infinity,
    }
  );

  const params = new URLSearchParams(window.location.search);

  if (params.get("success")) {
    const $pepMarkedCompleteModal = $("#pepMarkedCompleteModal");
    const $timeProgress = $pepMarkedCompleteModal.find(".progress-bar")
    const timeout = 5000;

    $pepMarkedCompleteModal.modal();
    $timeProgress.animate({width: "0%"}, timeout)

    setTimeout(() => {
      $pepMarkedCompleteModal.modal("hide");
    }, timeout);
  }

  // -----------------------------------
  // resource form fields, show/hide pep_question select dropdown based on the categories selected


  function initResourceCategoryQuestionsToggle(){
    const checkboxSelector = "input.pep_category_id_checkboxes"
    const selectSelector = "select.pep_question_id_selects"
    const containerClassPrefix = ".pep_category_questions_"

    function initResourceQuestionContainers(){
      $("div.pep-category-id-results input").each(function(){
        $(containerClassPrefix + this.value).fadeIn('fast');
        $(containerClassPrefix + this.value + ' select').prop('disabled', false);
      });
    }

    function updateResourceQuestionContainer(category_id, checked){
      if (checked) {
        $(containerClassPrefix + category_id).fadeIn('fast');
        $(containerClassPrefix + category_id + ' select').prop('disabled', false);
      } else {
        $(containerClassPrefix + category_id).fadeOut('fast');
        $(containerClassPrefix + category_id + ' select').prop('disabled', 'disabled');
      }
    }

    // Toggle the containers on page load
    initResourceQuestionContainers();

    $(checkboxSelector).change(function() {
      $("div.pep-category-id-results input[value='" + this.value + "']").remove();
      if(this.checked) {
        $("div.pep-category-id-results").append("<input type='hidden' name='resource[pep_category_ids][]' value='" + this.value + "'>");
      }

      $("#pep_category_en_ids_" + this.value).prop("checked", this.checked);
      $("#pep_category_fr_ids_" + this.value).prop("checked", this.checked);

      // Toggle the containers whenever a checkbox is selected/unselected
      updateResourceQuestionContainer(this.value, this.checked);
    });

    $(selectSelector).change(function() {
      const category_id = $(this).data("category-id");

      $(".pep_question_id_selects[data-category-id='" + category_id + "'] option").map(function() { return this.value; }).each(function(){
        $("div.pep-question-id-results input[value='" + this + "']").remove();
      });

      if(this.value) {
        $("div.pep-question-id-results").append("<input type='hidden' name='resource[pep_question_ids][]' value='" + this.value + "'>");
      }

      $("#pep_question_en_ids_" + category_id).val(this.value);
      $("#pep_question_fr_ids_" + category_id).val(this.value);
    });
  }

  function initAgeLevel(){
    const selectSelector = "select.age_level_selects"

    $(selectSelector).change(function(e) {
      $(selectSelector).val(this.value);

      $("input[name='resource[age_level]']").val(this.value);
    });
  }

  initResourceCategoryQuestionsToggle();
  initAgeLevel();
});

$(document).on("turbo:before-cache", function () {
  $(".pep-program-select").select2("destroy");
});

$(document).on("loadedFilterrificResults", function () {
  attachCheckboxEvents();
});

const isElementTopInViewport = (el) => {
  var rect = el.getBoundingClientRect();
  return rect.top <= (window.innerHeight || document.documentElement.clientHeight);
};

const didElementHitTopViewport = (el) => {
  var rect = el.getBoundingClientRect();
  return rect.top <= 20;
};

function attachCheckboxEvents() {
  const $checkboxes = $(".tally-checkbox");

  if ($checkboxes.length) {
    $("#submit-tally").show();
    $checkboxes.change(initializePepComparison);
  } else {
    $("#submit-tally").hide();
  }
}

function initializePepComparison() {
  const checkedCheckboxes = $(".tally-checkbox:checked");
  const len = checkedCheckboxes.length;
  if (len === 1) {
    restrictTallyCheckboxes(checkedCheckboxes[0]);
    disableCompareSubmitButton();
  } else if (len > 1) {
    enableCompareSubmitButton();
  } else {
    $(".tally-checkbox").removeAttr("disabled");
    disableCompareSubmitButton();
  }
}

function restrictTallyCheckboxes(elm) {
  const programId = $(elm).data("program-id");
  $(".tally-checkbox").each(function (i, obj) {
    if ($(obj).data("program-id") != programId) {
      $(obj).attr("disabled", true);
    }
  });
}

function enableCompareSubmitButton() {
  $("#submit-tally").removeAttr("disabled");
  $("#submit-tally").prop("value", locale_translation.peps.compare_pep_tallies);
}

function disableCompareSubmitButton() {
  $("#submit-tally").prop("disabled", true);
  $("#submit-tally").prop("value", locale_translation.peps.select_peps_compare);
}

function disableAndSpin(btn, text = locale_translation.uploading) {
  $(btn).html(`${text} <i class='fas fa-spinner fa-pulse'>`).prop("disabled", true);
}

const windowObserver = () => {
  const recommendations = document.getElementById("PepNarrativeRecommendations");
  const followBox = document.getElementById("follow-box");

  if (!followBox || !recommendations) return;
  if (!isElementTopInViewport(recommendations) && didElementHitTopViewport(followBox)) {
    // followBox.classList.add('js-stick-it');
    $(".follow-box-side").fadeIn("250");
  } else if (isElementTopInViewport(recommendations)) {
    $(".follow-box-side").fadeOut("250");
  } else {
    $(".follow-box-side").fadeOut("250");
  }

  if (isElementTopInViewport(recommendations)) {
    $(followBox).fadeOut(250);
  } else {
    $(followBox).fadeIn(250);
  }
};

window.addEventListener("resize", windowObserver);
window.addEventListener("scroll", windowObserver);
$(document).on("turbo:load", windowObserver);
