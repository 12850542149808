$(document).on('turbo:load', function() {

  let $select_program = $("#sign_up_form_program_id");
  let $select_program_clone = $select_program.clone();
  const $other_str = locale_translation.programs.other_program

  /**
   * Updates the drop-down list of programs based on the city.
   * @param {Object} $city_id String of city id for which we want to show programs for.
   */
  function update_programs_list($city_id) {
    $select_program.find("option").remove();
    $select_program.append($select_program_clone.find("option:first").clone()); // Add initial empty option
    $select_program.append($select_program_clone.find("option[data-city-id='" + $city_id +"']").clone());
    $select_program.append(new Option($other_str, $other_str));
  }

  let $program_selection = $(".sign-up-program-selection");
  let $city_selection = $(".sign-up-city-selection");
  let $user_details = $(".sign-up-user-details");
  let $contact_ahs = $(".sign-up-contact-ahs");

  /**
   * Checks if a province and program is selected and when selected,
   * shows the user details or contact AHS section.
   * @param {Object} $province_id String of province id.
   */
  function check_and_show_form($province_id, $city_id, $program_id) {
    if ($province_id == '') {
      $city_selection.hide();
      $program_selection.hide();
      $user_details.hide();
      $contact_ahs.hide();
    } else if ($select_program_clone.find("option[data-province-id='" + $province_id +"']").length) {
      $city_selection.show();

      if ($city_id == '') {
        $program_selection.hide();
        $user_details.hide();
        $contact_ahs.hide();
      } else if ($select_program_clone.find("option[data-city-id='" + $city_id +"']").length) {
        $program_selection.show();

        if ($program_id == '') {
          $user_details.hide();
          $contact_ahs.hide();
        } else if ($program_id == $other_str) {
          $user_details.hide();
          $contact_ahs.show();
        } else {
          $user_details.show();
          $contact_ahs.hide();
        }
      } else {
        $program_selection.hide();
        $user_details.hide();
        $contact_ahs.show();
      }
    } else {
      $city_selection.hide();
      $program_selection.hide();
      $user_details.hide();
      $contact_ahs.show();
    }
  }

  let $province_id = $("#sign_up_form_province_id");
  let $city_id = $("#sign_up_form_city_id");
  $province_id.change(function() {
    check_and_show_form($(this).val(), $city_id.val(), $select_program.val());
  });
  $city_id.change(function() {
    update_programs_list($(this).val());
    check_and_show_form($province_id.val(), $(this).val(), $select_program.val());
  });
  $select_program.change(function() {
    check_and_show_form($province_id.val(), $city_id.val(), $(this).val());
  });

  check_and_show_form($province_id.val(), $city_id.val(), $select_program.val());
});
